import React from 'react'

export function Cross() {
	return (
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 41.72 41.72">
			<path
				fill="currentColor"
				d="M9.88,1.14L47.36,38.62l-4.24,4.24L5.64,5.38Z"
				transform="translate(-5.64 -1.14)"
			/>
			<path
				fill="currentColor"
				d="M5.64,38.62L43.12,1.14l4.24,4.24L9.88,42.86Z"
				transform="translate(-5.64 -1.14)"
			/>
		</svg>
	)
}
