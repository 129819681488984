import clsx from 'clsx'
import type { ReactNode } from 'react'
import styles from '../app/styles/Link.module.sass'

type ButtonProps = {
	onClick?: React.MouseEventHandler<HTMLButtonElement>
	children: ReactNode
	className?: string
}

export function Button(props: ButtonProps) {
	return (
		<button
			onClick={props.onClick}
			type="button"
			className={clsx(styles.btn, props.className && styles[props.className])}>
			{props.children}
		</button>
	)
}
